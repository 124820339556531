

































































































































import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import PollutantBar from '../components/PollutantBar.vue';
import PollutantBarMobile from '../components/PollutantBarMobile.vue';
import date from 'quasar/src/utils/date.js';;
import CCToggle from '../components/CCToggle.vue';
import CCGraph from '../components/CCGraph.vue';
import { SocketIOMixin } from '../io-utils';
import { apiGet } from '../store';
const { addToDate } = date;
import { i18n } from '../i18n';
import { POLLUTANTS_FILTER_OPTIONS, getPollutantsOptionsForAnalyzer } from '@/pollutants';
import AnalyzerAddEdit from './admin/AnalyzerEditCalibration.vue';


@Component({
  components: {
    CCToggle,
    CCGraph,
    PollutantBar,
    PollutantBarMobile,
  },
})
export default class AirQualityOutdoorGraph extends Mixins(SocketIOMixin) {
  @Prop() public analyzerId!: number;
  public $store: any;

  public dateRanges: any = [
    {value: 'realtime', label: i18n.t('DR_REALTIME')},
    {value: 'today', label: i18n.t('DR_TODAY')},
    {value: 'yesterday', label: i18n.t('DR_YESTERDAY')},
    {value: '7days', label: i18n.t('DR_LAST_7DAYS')},
    {value: '30days', label: i18n.t('DR_LAST_30DAYS')},
    {value: 'thisyear', label: i18n.t('DR_THIS_YEAR')},
    // {value: 'custom', label: 'Personnalisé'},
  ];
  public dateRangeValue: string = 'realtime';
  public dateRangeCustomFrom: any = null;
  public dateRangeCustomTo: any = null;
  public filterOptions: any = POLLUTANTS_FILTER_OPTIONS;
  public selectedFilterOptions: any = POLLUTANTS_FILTER_OPTIONS;
  public loading: boolean = false;
  public popupFilter: boolean = false;
  public popupDateRange: boolean = false;
  public popupFilterBefore: boolean = false;
  public popupDateRangeBefore: boolean = false;
  public needRefresh: boolean = false;

  public analyzer: any = null;
  public analyzerLastData: any = null;


  @Watch('analyzer')
  public refreshFilterOptions() {
    this.filterOptions = getPollutantsOptionsForAnalyzer(this.analyzer);
    this.selectedFilterOptions = this.selectedFilterOptions.filter(
      (entry: any) => (this.filterOptions.indexOf(entry) !== -1));
  }

  public created() {
    this.loading = true;

    const graphDateRange = localStorage.getItem('outdoorGraphDateRange');
    const graphPollutants = localStorage.getItem('outdoorGraphPollutants');

    if (graphDateRange !== undefined && graphDateRange !== null) {
      this.dateRangeValue = JSON.parse(graphDateRange);
    }
    if (graphPollutants !== undefined && graphPollutants !== null) {
      const graphPollutantsParsed: any = JSON.parse(graphPollutants) || [];
      this.selectedFilterOptions = this.filterOptions.filter(
        (entry: any) => (graphPollutantsParsed.indexOf(entry.value) !== -1));
    }

    this.refresh();
    this.loadAnalyzerData();
  }

  public refresh() {
    const analyzer = this.$store.getters.getAnalyzerFromId(this.analyzerId);
    if (analyzer === null) {
      apiGet(`/analyzer/${this.analyzerId}`)
      .then((response: any) => {
        this.$store.commit('setAnalyzer', {
          analyzer: response,
        });
        this.analyzer = response;
        this.loading = false;
      })
      .catch((error: any) => {
        this.globalError(error);
      });
    } else {
      this.analyzer = analyzer;
      this.loading = false;
    }
  }

  public loadAnalyzerData() {
    apiGet(`/analyzer/${this.analyzerId}/data/last`)
    .then((response: any) => {
      this.updateFromResponse(response);
    })
    .catch((error: any) => {
      this.globalError(error);
    });
  }

  public updateFromResponse(response: any) {
      this.analyzerLastData = response;
      // if (response.online === false || response.data.time === null) {
      //   this.isOnline = false;
      //   this.showTipsDialog = false;
      //   this.showMobileSituations = false;
      //   this.showMobileMessages = false;
      //   this.lastUpdatedAt = this.areaLastData.last_updated_at;
      //   this.areaLastData.data.qai = 3;
      //   this.areaQai = 3;
      // } else {
      //   this.isOnline = true;
      //   this.areaQai = this.areaLastData.data.qai;
      // }
  }

  public selectFilterOption(option: any) {
    if (this.selectedFilterOptionsValues.indexOf(option.value) === -1) {
      this.selectedFilterOptions = this.filterOptions.filter(
        (entry: any) => (
          this.selectedFilterOptionsValues.indexOf(entry.value) !== -1
          || option.value === entry.value));
    } else {
      if (this.selectedFilterOptions.length === 1) {
        return;
      }
      this.selectedFilterOptions = this.selectedFilterOptions.filter(
        (entry: any) => entry.value !== option.value);
    }
  }

  get selectedFilterOptionsValues() {
    return this.selectedFilterOptions.map((entry: any) => entry.value);
  }

  @Watch('selectedFilterOptionsValues')
  public saveSelectedFilterOptionsValues() {
    localStorage.setItem(
      'outdoorGraphPollutants',
      JSON.stringify(this.selectedFilterOptionsValues));
  }

  @Watch('dateRangeValue')
  public saveDateRangeValue() {
    localStorage.setItem(
      'outdoorGraphDateRange',
      JSON.stringify(this.dateRangeValue));
  }

  @Watch('analyzerId')
  public onParamsChanged() {
    this.needRefresh = true;
    this.$nextTick().then(() => {
      if (this.needRefresh) {
        this.loading = true;
        this.refresh();
        this.needRefresh = false;
      }
    });
  }

  get dateRangeFrom() {
    const drv = this.dateRangeValue;
    // Get UTC date
    // see https://stackoverflow.com/questions/948532/how-do-you-convert-a-javascript-date-to-utc
    const localedate = new Date();
    const utcnow =  new Date(
      Date.UTC(
        localedate.getUTCFullYear(),
        localedate.getUTCMonth(),
        localedate.getUTCDate(),
        localedate.getUTCHours(),
        localedate.getUTCMinutes(),
        localedate.getUTCSeconds(),
      ));

    if (drv === 'realtime') {
      return null;
    } else if (drv === 'today') {
      return date.startOfDate(utcnow, 'day');
    } else if (drv === 'yesterday') {
      return date.startOfDate(addToDate(utcnow, { days: -1 }), 'day');
    } else if (drv === '7days') {
      return date.startOfDate(addToDate(utcnow, { days: -7 }), 'day');
    } else if (drv === '30days') {
      return date.startOfDate(addToDate(utcnow, { days: -30 }), 'day');
    } else if (drv === 'thisyear') {
      return date.startOfDate(addToDate(utcnow, { year: -1 }), 'day');
    } else if (drv === 'custom') {
      return this.dateRangeCustomFrom;
    }
  }

  get dateRangeText() {
    if (this.dateRangeValue === 'custom') {
      //
    } else {
      return this.dateRanges.filter((entry: any) => {
        return entry.value === this.dateRangeValue;
      })[0].label;
    }
  }

  get dateRangeTo() {
    const drv = this.dateRangeValue;
    if (drv === 'realtime') {
      return null;
    } else if (drv === 'today') {
      return addToDate(this.dateRangeFrom, { days: 1 });
    } else if (drv === 'yesterday') {
      return addToDate(this.dateRangeFrom, { days: 1 });
    } else if (drv === '7days') {
      return addToDate(this.dateRangeFrom, { days: 7 });
    } else if (drv === '30days') {
      return addToDate(this.dateRangeFrom, { days: 30 });
    } else if (drv === 'thisyear') {
      return addToDate(this.dateRangeFrom, { year: 1 });
    } else if (drv === 'custom') {
      return this.dateRangeCustomTo;
    }
  }

  get dateRangeLabelFormat() {
    const drv = this.dateRangeValue;
    if (drv === 'realtime') {
      return 'HH:mm';
    } else if (drv === 'today') {
      return 'HH:mm';
    } else if (drv === 'yesterday') {
      return 'HH:mm';
    } else if (drv === '7days') {
      return 'DD/MM HH';
    } else if (drv === '30days') {
      return 'DD/MM';
    } else if (drv === 'thisyear') {
      return 'MM/YY';
    } else if (drv === 'custom') {
      return 'DD/MM HH:mm';
    }
  }



}
